import { toast } from 'react-hot-toast'
import { X } from '@phosphor-icons/react'
import * as React from 'react'

export const errorToast = (error?: string) =>
  toast(
    (t) => (
      <span className="flex justify-between">
        {error || (
          <>
            Something went wrong. Please try again or&nbsp;
            <a
              className="text-red-600 underline"
              href="mailto:support@progressionapp.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact support
            </a>
            .
          </>
        )}
        <button
          aria-label="Close"
          className="close"
          onClick={() => toast.dismiss(t.id)}
        >
          <X className="h-4 text-red-600 w-4" weight="bold" />
        </button>
      </span>
    ),
    {
      className: 'bg-red-100 text-red-600',
    }
  )
